import { getCookie } from "./cookies";
import TagManager from "react-gtm-module";

let GA_TRACKING_ID = "G-B2PD0GJT1X";

export const pageview = (url: string) => {
  try {
    if (getCookie("cookie-pref") != "decline") {
      (window as any).gtag("config", GA_TRACKING_ID, {
        page_path: url,
      });
    }
  } catch (err) {
    // console.error(err);
  }
};

export const event = ({ action, category, label, value }: any) => {
  try {
    if (getCookie("cookie-pref") != "decline") {
      return (window as any).gtag("event", action, {
        event_category: category,
        event_label: label,
        value: value,
      });
    }
  } catch (err) {
    // console.log(err);
  }
};

export const TagManagerEvent = (data: any) => {
  try {
    if (getCookie("cookie-pref") != "decline") {
      TagManager.dataLayer({
        dataLayer: data,
      });
    }
  } catch (err) {
    // console.log(err);
  }
};
