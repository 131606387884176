import { FunctionComponent } from "react";
import styled from "styled-components";

interface FooterLinkIconProps {
  isOpen?: boolean;
}

const FooterLinkIconWrapper = styled.div<FooterLinkIconProps>`
  transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "")};
`;

export const FooterLinkIcon: FunctionComponent<FooterLinkIconProps> = (
  props
) => {
  return (
    <FooterLinkIconWrapper isOpen={props.isOpen}>
      <svg
        width="12"
        height="7"
        viewBox="0 0 12 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1L6 6L11 1"
          stroke="white"
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </FooterLinkIconWrapper>
  );
};
