import Link from "next/link";
import { FunctionComponent, useEffect, useState } from "react";
import { HamburgerIcon } from "../../icons/hamburger";
import { event } from "../../../utils/gtag";
import { useRouter } from "next/router";
import {
  HeaderWrapper,
  HomeContainer,
  HeaderLogo,
  MenuItemsSection,
  HamburgerMenuIconSection,
  SliderSection,
  MenuItem,
  LocationDropDownContainer,
  LocationDropdown,
  Country,
  LocationDropDownWrapper,
  LanguageSwitcher,
} from "./styles";
import { MobileMenuItem } from "./mobile-menu-item";
import {
  displayHeaderLanguageToggle,
  headerCountryDropdown,
  languageToggleData,
  toggleDefaultStatus,
} from "../../../utils/intlnUtils";
import { eraseCookie, getCookie, setCookie } from "../../../utils/cookies";

interface HeaderHeaderData {
  logo: string;
  menuItems: MenuItem[];
  linkColor?: string;
  handleCountryChange?: (value: string) => void;
  country?: string;
  loadedFromOtherDomain?: boolean;
  userRegion?: string;
}
export interface MenuItem {
  fieldName: string;
  id: string;
  url: string;
  visible: boolean;
  children?: any[];
}

export const Header: FunctionComponent<HeaderHeaderData> = (props) => {
  const router: any = useRouter();
  const [isHamburgerOpen, setHamburgerOpen] = useState<boolean>(false);
  const [expandedMenu, setExapandedMenu] = useState("");
  const [country, setCountry] = useState("");
  const [openLocaleDropdown, setOpenLocaleDropdown] = useState(false);
  const [displayLanguageToggle, setDisplayLanguageToggle] = useState(
    displayHeaderLanguageToggle[router.locale || ""] ? true : false
  );

  useEffect(() => {
    let country = headerCountryDropdown[router.locale];
    if (!country) {
      setCountry("ROW");
    } else {
      setCountry(country);
    }
  }, []);

  useEffect(() => {
    setHamburgerOpen(false);
  }, [router.pathname]);

  useEffect(() => {
    setDisplayLanguageToggle(
      displayHeaderLanguageToggle[router.locale || ""] ? true : false
    );
  }, [router.locale]);

  const handleLinkClick = (e: any) => {
    event({
      action: "click",
      category: "Header",
      label: "Header links",
      value: e.target.getAttribute("data-url"),
    });
  };

  const submenuView = (menuItem: any) => {
    return menuItem.children?.map((children: any, index: number) => {
      return (
        <Link
          className="submenu-link"
          key={index}
          href={children.url}
          target={children.target}
          locale={router.locale}
        >
          {children.fieldName}
        </Link>
      );
    });
  };

  const highlightMenuItem = (children: any[]) => {
    let isActive = false;
    children.map((child: any) => {
      if (
        child.url === router.pathname ||
        (router.pathname.includes(child.url) &&
          router.pathname != "/" &&
          child.url != "/")
      ) {
        console.log("test url : ", router.pathname, child.url);

        isActive = true;
      }
    });
    return isActive;
  };

  function generateMenuItems() {
    let view = props?.menuItems?.map((menuItem: any, index: number) => {
      let hrefUrl = `${menuItem.url}`;
      return (
        <div className="menu-link" key={index}>
          <MenuItem
            isActive={
              menuItem.children.length > 0
                ? highlightMenuItem(menuItem.children)
                : menuItem.url === router.pathname ||
                  (router.pathname.includes(menuItem.url) &&
                    router.pathname != "/" &&
                    menuItem.url != "/")
            }
            isGrayFont={router.pathname.includes("advertising")}
          >
            <div className="desktop-menu-item">
              {menuItem.children.length > 0 ? (
                <div
                  className="desktop-item"
                  data-url={menuItem.url}
                  onClick={handleLinkClick}
                >
                  {menuItem.fieldName}
                </div>
              ) : (
                <Link href={menuItem.url || ""}>{menuItem.fieldName}</Link>
              )}
              {menuItem.children.length > 0 && (
                <div className="submenu-container">
                  <div className="empty-item"></div>
                  <div className="submenu-section">{submenuView(menuItem)}</div>
                </div>
              )}
            </div>
            <div className="mobile-menu-item">
              <MobileMenuItem
                title={menuItem.fieldName}
                menuChildren={menuItem.children}
                handleExpandedMenu={handleExpandedMenu}
                expand={expandedMenu === menuItem.fieldName}
                handleMenuClose={handleMenuClose}
                titleLink={menuItem.url}
              />
            </div>
          </MenuItem>
        </div>
      );
    });
    return view;
  }

  function handleExpandedMenu(value: any) {
    setExapandedMenu(value);
  }

  const handleMenuClose = () => {
    setHamburgerOpen(false);
  };

  const handleCountrySelection = (e: any) => {
    let locale =
      e.target.getAttribute("data-locale") == "en"
        ? ""
        : e.target.getAttribute("data-locale") == "id"
        ? "id"
        : e.target.getAttribute("data-locale");

    let redirectUrl = "";

    if (e.target.getAttribute("data-locale") == "en") {
      locale = "";
    }

    if (e.target.getAttribute("data-country") == "ROW") {
      locale = "row";
    }
    /* Special case: If the region is set to "en", the URL will be in the format of "glance.com/[page-name]" 
       without a locale. IN this case, we will slice the URL from index 1 .
     */
    if (router.locale == "en") {
      setTimeout(() => {
        if (
          e.target.getAttribute("data-country") == "India" &&
          getCookie("userRegion") == "in"
        ) {
          if (window.location.pathname.split("/").splice(1).join("/")) {
            redirectUrl = `${window.location.origin}/${window.location.pathname
              .split("/")
              .splice(1)
              .join("/")}`;
          } else {
            redirectUrl = `${window.location.origin}`;
          }
        } else {
          if (window.location.pathname.split("/").splice(1).join("/")) {
            redirectUrl = `${
              window.location.origin
            }/${locale}/${window.location.pathname
              .split("/")
              .splice(1)
              .join("/")}`;
          } else {
            redirectUrl = `${window.location.origin}/${locale}`;
          }
        }

        window.location.href = redirectUrl;
      }, 500);
    } else {
      if (
        e.target.getAttribute("data-country") == "India" &&
        getCookie("userRegion") == "in"
      ) {
        if (window.location.pathname.split("/").splice(2).join("/")) {
          redirectUrl = `${window.location.origin}/${window.location.pathname
            .split("/")
            .splice(2)
            .join("/")}`;
        } else {
          redirectUrl = `${window.location.origin}`;
        }
      } else {
        if (window.location.pathname.split("/").splice(2).join("/")) {
          redirectUrl = `${
            window.location.origin
          }/${locale}/${window.location.pathname
            .split("/")
            .splice(2)
            .join("/")}`;
        } else {
          redirectUrl = `${window.location.origin}/${locale}`;
        }
      }

      window.location.href = redirectUrl;
    }
  };

  const handleLanguageSwitch = (e: any) => {
    if (toggleDefaultStatus[router.locale || ""]) {
      if (window.location.pathname.split("/").splice(2).join("/")) {
        window.location.href = `${window.location.origin}/${
          languageToggleData[router.locale || ""]
        }/${window.location.pathname.split("/").splice(2).join("/")}`;
      } else {
        window.location.href = `${window.location.origin}/${
          languageToggleData[router.locale || ""]
        }`;
      }
    } else {
      if (window.location.pathname.split("/").splice(2).join("/")) {
        window.location.href = `${window.location.origin}/${
          languageToggleData[router.locale || ""]
        }/${window.location.pathname.split("/").splice(2).join("/")}`;
      } else {
        window.location.href = `${window.location.origin}/${
          languageToggleData[router.locale || ""]
        }`;
      }
    }
  };

  return (
    <HeaderWrapper>
      <HomeContainer>
        <HeaderLogo
          onClick={() => {
            if (!props.loadedFromOtherDomain) {
              if (router.pathname === "/") {
                window.location.reload();
              } else {
                router.push("/");
              }
            } else {
              window.open("https://glance.com", "_self");
            }
          }}
          src={props.logo}
          alt="glance"
          height={32}
        />
        <MenuItemsSection>
          {generateMenuItems()}
          {displayLanguageToggle && (
            <LanguageSwitcher
              color={
                toggleDefaultStatus[router.locale || ""] ? "white" : "grey"
              }
              borderBottom={
                toggleDefaultStatus[router.locale || ""]
                  ? "1px solid white"
                  : "none"
              }
              onClick={handleLanguageSwitch}
            >
              en
            </LanguageSwitcher>
          )}
          <LocationDropDownContainer
            onMouseOver={(e: any) => {
              setOpenLocaleDropdown(true);
              e.stopPropagation();
            }}
            onMouseLeave={(e: any) => {
              setOpenLocaleDropdown(false);
              e.stopPropagation();
            }}
          >
            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="selected-country">
                {country}{" "}
                <svg
                  width="8"
                  height="6"
                  viewBox="0 0 8 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1 1L4 4L7 1" stroke="white" stroke-width="1.5" />
                </svg>
              </div>

              {openLocaleDropdown && (
                <LocationDropDownWrapper>
                  <div className="empty-item"></div>
                  <LocationDropdown>
                    <Country
                      data-country="India"
                      data-locale="en"
                      data-country-mobile="IN"
                      onClick={handleCountrySelection}
                      className={
                        country === "India" || country == "IN" ? "active" : ""
                      }
                    >
                      India
                    </Country>

                    <Country
                      data-country="Indonesia"
                      data-country-mobile="ID"
                      data-locale="id"
                      onClick={handleCountrySelection}
                      className={
                        country === "Indonesia" || country == "ID"
                          ? "active"
                          : ""
                      }
                    >
                      Indonesia
                    </Country>

                    <Country
                      data-country="Japan"
                      data-country-mobile="JP"
                      data-locale="jp"
                      onClick={handleCountrySelection}
                      className={
                        country === "Japan" || country == "JP" ? "active" : ""
                      }
                    >
                      Japan
                    </Country>

                    <Country
                      data-country="US"
                      data-locale="us"
                      data-country-mobile="US"
                      onClick={handleCountrySelection}
                      className={
                        country === "US" || country == "US" ? "active" : ""
                      }
                    >
                      USA
                    </Country>

                    <Country
                      data-country="ROW"
                      data-locale="row"
                      data-country-mobile="ROW"
                      onClick={handleCountrySelection}
                      className={country == "ROW" ? "active" : ""}
                    >
                      Rest of World
                    </Country>
                  </LocationDropdown>
                </LocationDropDownWrapper>
              )}
            </div>
          </LocationDropDownContainer>
          <HamburgerMenuIconSection
            onClick={() => {
              setExapandedMenu("");
              setHamburgerOpen(!isHamburgerOpen);
            }}
          >
            <HamburgerIcon isOpen={isHamburgerOpen} />
          </HamburgerMenuIconSection>
        </MenuItemsSection>
        <SliderSection isOpen={isHamburgerOpen}>
          {generateMenuItems()}
        </SliderSection>
      </HomeContainer>
    </HeaderWrapper>
  );
};
