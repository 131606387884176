import { ENDPOINTS } from "../../constants/endpoints/endpoints";
import APIRequest from "../networking/APIRequest";
import { fetchRequest } from "../networking/APIRequester";
import HTTPMethod from "../networking/HTTPMethod";

export const getFooterDetails = async (locale?: string) => {
  let request: any;
  if (locale) {
    request = new APIRequest(
      `${ENDPOINTS.footer.getDetails}&locale=${locale}`,
      HTTPMethod.get
    );
  } else {
    request = new APIRequest(`${ENDPOINTS.footer.getDetails}`, HTTPMethod.get);
  }

  return fetchRequest(request);
};
