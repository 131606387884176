import "../styles/globals.css";
import { Footer } from "../components/common/footer";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import HTTPResponse from "../services/networking/HTTPResponse";
import { getFooterDetails } from "../services/footer";
import Cookie from "../components/common/cookie-consent";
import { Header } from "../components/common/header";
import styled from "styled-components";
import { Seo, SeoDetails } from "../components/common/seo";
import { fetchHeaderDetails } from "../services/header";
import {
  cookiePolicyTranslations,
  eraseCookie,
  getCookie,
  setCookie,
} from "../utils/cookies";
import NextNProgress from "nextjs-progressbar";
import Head from "next/head";
import axios from "axios";
import TagManager, { TagManagerArgs } from "react-gtm-module";
import * as cookie from "cookie";
import { CountrySelector } from "../components/common/country-banner";
import {
  availableCountriesForIntl,
  geoAPIRegionToLocale,
  localeToGeoAPIRegion,
} from "../utils/intlnUtils";

import { appWithTranslation, useTranslation } from "next-i18next";
import { i18n } from "../../next-i18next.config";

export interface IApp {
  handleDisplayFooter: (value: boolean) => void;
  handleSeoDetails: (value: SeoDetails) => void;
  handleHeaderLinkColor: (value: string) => void;
  handleCookieConsent: (value: boolean) => void;
  handleDisplayHeader: (value: boolean) => void;
  disableDefaultSeo: () => void;
  awsCdn: string;
}

const AppContainer = styled.div<any>`
  width: 100%;
  min-height: 100vh;
`;

function App({
  Component,
  pageProps,
  footerDetails,
  headerDetails,
  awsCdn,
  enableHeaderByDefault,
  enableFooterByDefault,
  redirectionData,
}: any) {
  const { t } = useTranslation("common");
  const router = useRouter();
  const [displayFooter, setDisplayFooter] = useState(enableFooterByDefault);
  const [displayHeader, setDisplayHeader] = useState(enableHeaderByDefault);
  const [displayCookieConsent, setDisplayCookieConsent] = useState(true);
  const [seoDetails, setSeoDetails] = useState<SeoDetails>({});
  const [headerLinkColor, setHeaderLinkColor] = useState<string>("");
  const [showDefaultSeo, setShowDefaultSeo] = useState<boolean>(true);

  //overriding console logs functionality to avoid logs in production
  console.log = () => {};

  let cookieConsent = getCookie("cookie-pref");

  function handleDisplayFooter(value: boolean) {
    setDisplayFooter(value);
  }
  function handleDisplayHeader(value: boolean) {
    setDisplayHeader(value);
  }

  function handleSeoDetailsChange(data: SeoDetails) {
    setSeoDetails(data);
  }

  function handleHeaderLinkColor(color: string) {
    setHeaderLinkColor(color);
  }

  function handleCookieConsent(value: boolean) {
    setDisplayCookieConsent(value);
  }

  function handleCountryChange(value: string) {}

  function disableDefaultSeo() {
    setShowDefaultSeo(false);
  }

  const tagManagerArgs: TagManagerArgs = {
    gtmId: "GTM-MV5WBGV",
  };

  useEffect(() => {
    if (cookieConsent !== "decline") {
      TagManager.initialize(tagManagerArgs);
    }
  }, []);

  return (
    <AppContainer>
      <Head>
        <link rel="preconnect" href="https://web.glance-cdn.com" />
      </Head>

      <NextNProgress
        color="#f93d66"
        options={{ easing: "ease", speed: 500, showSpinner: false }}
      />
      <></>
      {showDefaultSeo && (
        <Seo
          title={seoDetails?.title}
          description={seoDetails?.description}
          keywords={seoDetails?.keywords}
          image={seoDetails?.image}
          canonical={seoDetails?.canonical}
          metaData={seoDetails?.metaData}
        />
      )}
      {displayHeader && (
        <Header
          logo={headerDetails?.data?.attributes?.logo}
          menuItems={headerDetails?.data?.attributes?.menuItems || []}
          linkColor={headerLinkColor}
          handleCountryChange={handleCountryChange}
          country={""}
        />
      )}
      <Component
        {...pageProps}
        handleDisplayFooter={handleDisplayFooter}
        handleDisplayHeader={handleDisplayHeader}
        handleSeoDetails={handleSeoDetailsChange}
        handleHeaderLinkColor={handleHeaderLinkColor}
        handleCookieConsent={handleCookieConsent}
        awsCdn={awsCdn}
        disableDefaultSeo={disableDefaultSeo}
      />
      {displayCookieConsent && (
        <Cookie
          consentText={
            cookiePolicyTranslations[(router as any).locale || "en"]
              ?.cookie_policy_copy
          }
          userRegion={redirectionData?.selectedRegionForBanner}
        />
      )}

      {displayFooter && (
        <Footer
          socialMediaItems={footerDetails?.socialIcons || []}
          copyrightImageUrl={footerDetails?.privacySeal || ""}
          copyrightText={footerDetails?.copyRIghtText || ""}
          footerGroups={footerDetails?.footerGroups}
        />
      )}
    </AppContainer>
  );
}

const getRegionLocale = (region: string) => {
  return geoAPIRegionToLocale[region];
};

function removeExtraSlash(str: string) {
  return str.replace(/\/\//g, "/");
}

App.getInitialProps = async (props: any) => {
  /*
   ******************** To avoid header flashing on page load ********************
   */
  const routesInWhichHeaderIsNotRequired = [
    "/[slug]",
    "/eula",
    "/privacy-policy",
    "/trusted-partners",
    "/aquos",
    "/jpsb/[slug]",
    "/jpsb/[slug]/[id]",
    "/jpsb/inst/[slug]",
    "/jpsb/inst/[slug]/[id]",
  ];
  const routesInWhichFooterIsNotRequired = [
    "/[slug]",
    "/eula",
    "/privacy-policy",
    "/trusted-partners",
    "/aquos",
    "/jpsb/[slug]",
    "/jpsb/[slug]/[id]",
    "/jpsb/inst/[slug]",
    "/jpsb/inst/[slug]/[id]",
  ];

  let enableHeaderByDefault: boolean = true;
  let enableFooterByDefault: boolean = true;
  if (routesInWhichHeaderIsNotRequired.includes(props.router.route)) {
    enableHeaderByDefault = false;
  }

  if (routesInWhichFooterIsNotRequired.includes(props.router.route)) {
    enableFooterByDefault = false;
  }

  /*
   ******************** End To avoid header flashing on page load ********************
   */

  let paths = ["/?/eula-samsung-sea-en", "/?/pp-samsung-sea-en"];
  if (paths.includes(props?.ctx?.req?.url)) {
    let url = props.ctx.req.url.split("/")[2];
    try {
      props.ctx.res.writeHead(301, { Location: `${url}` });
      props.ctx.res.end();
    } catch (error) {
      // console.log("error id redirecting", error);
    }
    return true;
  }

  /*
   ******************** Internationalization logic ********************
   */

  const routesInWhichInternationalisationIsNotRequired = [
    "/eula",
    "/privacy-policy",
  ];

  const parsedCookies = cookie.parse(props.ctx?.req?.headers?.cookie || "");
  let userRegion: any = parsedCookies.userRegion || "";

  const userAgent = props.ctx.req.headers["user-agent"] || "";

  const isGooglebot = isBot(userAgent);

  try {
    if (
      (!props.ctx.req.headers.referer ||
        props.ctx.req?.headers["sec-fetch-site"] !== "same-origin") &&
      !routesInWhichInternationalisationIsNotRequired.includes(
        props.router.route
      ) &&
      !isGooglebot
    ) {
      let response = await axios.get(`https://glance.com/api/location`, {
        headers: {
          "X-FORWARDED-FOR": props.ctx.req.headers["x-forwarded-for"],
          "X-api-key": "ec65d4939b9e6836701f785a407ffd20",
        },
      });

      props.ctx.res.setHeader(
        "set-cookie",
        cookie.serialize(
          "userRegion",
          response.data.userLocationDetails.countryCode,
          {
            expires: new Date(Date.now() + 86400000),
            path: "/",
          }
        )
      );

      userRegion =
        availableCountriesForIntl[
          response?.data?.userLocationDetails?.countryCode
        ] || "row";

      if (props.router.locale == i18n.defaultLocale) {
        const userLocale = getRegionLocale(
          availableCountriesForIntl[
            response?.data?.userLocationDetails?.countryCode
          ] || "row"
        );

        const isUserRequestedForOtherRegion =
          userLocale !== props.router.locale;

        if (isUserRequestedForOtherRegion) {
          props.ctx.res.writeHead(302, {
            Location: removeExtraSlash(`/${userLocale}/${props.ctx.req.url}`),
          });
          props.ctx.res.end();
        }
      }
    }
  } catch (err) {
    userRegion = "in";
  }

  let footerDetails = {};
  let headerResponse = {};
  try {
    headerResponse = await fetchHeaderDetails(props.router.locale);
    let response: HTTPResponse = await getFooterDetails(props.router.locale);
    footerDetails = response.data?.attributes;
  } catch (err) {
    // console.log(err);
  }

  /*
   ******************** End Internationalization logic ********************
   */

  return {
    footerDetails,
    headerDetails: headerResponse,
    awsCdn: process.env.NEXT_PUBLIC_AWS_CDN_HOST,
    enableHeaderByDefault,
    enableFooterByDefault,
    detailsDescription: {},
    redirectionData: {
      selectedRegionForBanner: userRegion,
    },
  };
};

function isBot(userAgent: string): boolean {
  return /bot|crawler|spider|crawling|googlebot|bingbot|slurp|duckduckbot|baiduspider|yandexbot|sogou|exabot|facebookexternalhit|facebot|ia_archiver|pinterest|linkedin|redditbot|mediapartners|applebot|amazonbot|cloudflare|yahoo|semrush|ahrefsbot|mj12bot|dotbot|rogerbot|blexbot|curl|wget|httpclient|python-requests|libwww-perl|screaming\s?frog/i.test(
    userAgent
  );
}

export default appWithTranslation(App);

