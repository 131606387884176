import APIRequest from "./APIRequest";

interface Data<T> {
  data: T;
  requestId: string;
}
export interface Response<T> {
  data: T | Data<T>;
  headers: { [key: string]: string };
  status: number;
  request: APIRequest;
}

export default class HTTPResponse<TResponseType = any> {
  data: TResponseType;
  headers: { [key: string]: string };
  statusCode: number;
  request: APIRequest;
  response: Response<TResponseType> | undefined;

  constructor(response: any) {
    if (
      response.data &&
      (response.data as Data<TResponseType>).data &&
      (response.data as Data<TResponseType>).requestId
    ) {
      this.data = (response.data as Data<TResponseType>).data;
    } else {
      this.data = response.data as TResponseType;
    }
    this.headers = response.headers;
    this.statusCode = response.status;
    this.request = response.request;
  }
}
