import DangerouslySetHtmlContent from "dangerously-set-html-content";
import Head from "next/head";
import { useRouter } from "next/router";
import { FunctionComponent } from "react";
const shortid = require("shortid");
import { i18n } from "../../../../next-i18next.config";

export interface SeoDetails {
  title?: string;
  description?: string;
  keywords?: string;
  image?: string;
  metaData?: Object;
  canonical?: string;
  hreflang?: any;
}
export const Seo: FunctionComponent<SeoDetails> = (props: SeoDetails) => {
  const router = useRouter();
  const canonical =
    router.locale !== i18n.defaultLocale && router.locale !== "row"
      ? `https://glance.com/${router.locale}${router.asPath}`
      : `https://glance.com${router.asPath}`;

  return (
    <Head>
      <title>{props.title}</title>
      <meta name="title" content={props.title} />
      <meta name="description" content={props.description} />
      <meta name="keywords" content={props.keywords} />
      <meta name="og:title" content={props.title} />
      <meta name="og:description" content={props.description} />
      <meta name="og:keywords" content={props.keywords} />
      {/* To check if seo object is present and then set og:image  */}
      {props?.title && (
        <meta
          property="og:image"
          content={
            props?.image
              ? props.image
              : "https://glance-web.glance-cdn.com/favicon.ico"
          }
        />
      )}

      {props?.metaData && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(props?.metaData) }}
        ></script>
      )}
      {props?.canonical ? (
        <link rel="canonical" href={props.canonical} />
      ) : (
        <link rel="canonical" href={canonical} />
      )}

      {props?.hreflang?.data?.map((item: any, key: any) => {
        return (
          <link
            key={shortid.generate()}
            rel="alternate"
            href={item.url}
            hrefLang={item.hreflang}
          />
        );
      })}
    </Head>
  );
};
