import { ENDPOINTS } from "../../constants/endpoints/endpoints";
import APIRequest from "../networking/APIRequest";
import { fetchRequest } from "../networking/APIRequester";
import HTTPMethod from "../networking/HTTPMethod";

export const fetchHeaderDetails = (locale?: string) => {
  let request;
  if (locale) {
    request = new APIRequest(
      `${ENDPOINTS.header.getDetails}&locale=${locale}`,
      HTTPMethod.get
    );
    return fetchRequest(request);
  } else {
    request = new APIRequest(`${ENDPOINTS.header.getDetails}`, HTTPMethod.get);
  }
  return fetchRequest(request);
};
