/*
  The following data used in _app.tsx to determine the locale
*/

import { i18n } from "../../next-i18next.config";

export const geoAPIRegionToLocale: any = {
  in: "en",
  id: "id",
  "id-ID": "id-ID",
  jp: "jp",
  us: "us",
  row: "row",
};

export const localeToGeoAPIRegion: any = {
  en: "in",
  //here it should be id:id, we have added id-ID because we dont have bahasa version yet
  id: "id",
  "id-ID": "id-ID",
  jp: "jp",
  "ja-JP": "jp",
  us: "us",
  row: "row",
};

/*
  The following data is used to determine which language toggle to display
  for the current locale. The language toggle is displayed in the header
  component.
*/

export const displayHeaderLanguageToggle: any = {
  id: true,
  "id-ID": true,
  jp: true,
  "ja-JP": true,
  us: false,
};

export const toggleDefaultStatus: any = {
  id: false,
  "id-ID": true,
  jp: false,
  "ja-JP": true,
  us: false,
};

export const languageToggleData: any = {
  id: "id-ID",
  "id-ID": "id",
  "ja-JP": "jp",
  jp: "ja-JP",
  us: "us",
};

/*
 ****************************************************
 */

export const headerCountryDropdown: any = {
  id: "ID",
  "id-ID": "ID",
  en: "IN",
  "ja-JP": "JP",
  jp: "JP",
  us: "US",
};

//add available geo api country codes below
export const availableCountriesForIntl: any = {
  id: "id",
  jp: "jp",
  in: "in",
  us: "us",
};

export const constructCanonical = (locale?: string, path?: string) => {
  if (locale && locale != i18n.defaultLocale && locale != "row") {
    return `https://glance.com/${locale}/${path}`;
  } else {
    return `https://glance.com/${path}`;
  }
};
