import styled from "styled-components";

export const FooterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  padding: 40px 12px;
  background-color: black;
  bottom: 0;
  justify-content: center;
  @media (max-width: 920px) {
    height: auto;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    display: none;
  }
`;
export const FooterLeftBody = styled.div`
  width: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 920px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;
export const FooterLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 4px;
  width: 210px;
  @media (max-width: 920px) {
    width: 40vw;
    margin: 4px 0px;
  }
`;
export const FooterItemsHeader = styled.div`
  display: flex;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  color: #f93c66;
  margin: 8px 0px;
  text-transform: capitalize;
  @media (max-width: 920px) {
    justify-content: space-between;
    align-items: center;
    margin: 6px 0px;
    cursor: pointer;
    font-size: 18px;
    font-weight: lighter;
    color: #fff;
    border-bottom: 0.1px solid #ffffff96;
    padding-bottom: 1rem;
  }
  div {
    display: none;
    cursor: pointer;
    @media (max-width: 920px) {
      display: block;
    }
  }
`;

export const FooterItemsBody = styled.div<any>`
  @media (max-width: 920px) {
    height: ${(props) => (props.isOpen ? "auto" : "0px")};
    overflow: hidden;
  }
`;

export const FooterItem = styled.div`
  font-size: 14px;
  color: white;
  margin: 12px 0px;
  cursor: pointer;
  @media (max-width: 920px) {
    margin: 12px 0px;
    font-size: 14px;
  }
`;
export const LegalSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 14px;
`;
export const FooterRightBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 3rem;
  /* width: 30vw; */
  .desktop-section {
    @media (max-width: 920px) {
      display: none;
      justify-content: center;
      align-items: center;
    }
  }
  @media (max-width: 920px) {
    margin-top: 32px;
    height: auto;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 2px !important;
  }
`;
export const SocialMediaSection = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 12px 0px;
  @media (max-width: 920px) {
    align-items: center;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(2, 0.2fr);
    gap: 10px;
  }
`;

export const SocialMediaIcon = styled.img`
  height: 28px;
  width: 28px;
  margin: 0px 2px;
  margin-right: 10px;
  @media (max-width: 920px) {
    height: 35px;
    width: 35px;
  }
`;

export const CopyrightSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 0px;
  color: white;
  img {
    width: 120px;
  }
  div {
    margin-top: 4px;
    font-size: 12px;
  }
  @media (max-width: 920px) {
    justify-content: center;
    align-items: center;
  }
`;

export const CompanyLogo = styled.div`
  margin: 12px 0px;
  img {
    width: 120px;
    @media (max-width: 920px) {
      width: 91px;
    }
  }
  @media (max-width: 920px) {
    margin: 12px 0px;
    width: 40vw;
    display: flex;
    justify-content: center;
    display: none;
  }
`;

export const MobileBottomBody = styled.div`
  display: none;
  @media (max-width: 920px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 14px;
    width: 100%;
    padding-left: 4rem;
  }
`;

export const DesktopFooter = styled.div`
  @media (max-width: 920px) {
    display: none;
  }
`;

export const MobileFooterContainer = styled.div`
  display: none;
  @media (max-width: 920px) {
    background-color: black;
    display: flex;
    background-color: black;
    height: auto;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
`;

export const MobileFooterContainerMain = styled.div`
  display: none;
  @media (max-width: 920px) {
    background-color: black;
    display: flex;
    min-height: 400px;
    width: 100vw;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

export const CopyrightText = styled.div`
  color: #ffffff33;
  font-size: 16px;
`;
