export const ENDPOINTS = {
  header: {
    getDetails: `header?populate=deep`,
  },
  footer: {
    getDetails: `footer?populate=*`,
  },
  newsroom: {
    getNewsroomDetails: `newsrooms?populate=*`,
    getNews: `news-data?populate=deep`,
    getPressReleases: `press-release?populate=deep`,
  },
  privacyPolicy: {
    getPrivacyPolicyDetails: `slug-pages?populate=deep&`,
  },
  indonesiaUnlocked: {
    indonesiaUnlockedDetails: `indonesia-unlocked?populate=*`,
  },
  slugs: {
    slugPages: `slug-pages?populate=*`,
  },
  careers: {
    getJobs: "boards/Glance/jobs?",
    getJobById: (id: string) => {
      return `boards/Glance/jobs/${id}?`;
    },
    getCareerPageDetails: "career?populate=deep",
  },
  compliance: {
    getReports: "compliances?",
  },
  company: {
    getDetails: "about-us?populate=*",
  },
  lockscreen: {
    getDetails: "lockscreen?populate=deep",
    getOemDeviceList: "oem-devices?sort=order&populate=deep",
  },
  legal: {
    getDetails: "legals?populate=deep&filters[slug][$eq]=",
  },

  blogs: {
    getBlogBySlug: (id: string, locale: string) => {
      return `blogs?populate=deep&filters[slug][$eq]=${id}&locale=${locale}`;
    },
    blogs: "blogs?populate=deep&sort=creationDate:desc",
  },
  pageSeo: {
    pageSeoDetails: "page-seos?populate=deep&filters[page][$eq]=",
  },
  articles: {
    getArticlesBySlug: (id: string, locale: string) => {
      return `articles?populate=deep&filters[slugId][$eq]=${id}&locale=${locale}`;
    },
    getAllArticles:
      "articles?populate=deep&pagination[page]=1&pagination[pageSize]=1000",
  },
  folders: {
    getDetails: "folders-page?populate=deep",
  },
  homescreen: {
    getDetails: "homescreen?populate=deep",
  },
  nostra: {
    getDetails: "nostra?populate=deep",
  },
  roposo: {
    getDetails: "roposo?populate=deep",
  },
  tv: {
    getDetails: "glance-tv?populate=deep",
  },

  helpCenter: {
    getDetails: "help-center?populate=deep",
    addLike: "add-like?slug=",
    contactus: "contact-us?populate=deep",
  },
  ads: {
    getDetails: (id: String) => {
      return `public-files?populate=deep&filters[slug][$eq]=${id}`;
    },
  },
  insights: {
    getDetails: (slug: String, pageType: string) => {
      return `insights-reports?populate=deep&filters[slug][$eq]=${slug}&filters[pageType][$eq]=${pageType}`;
    },
  },
  creativeHub: {
    getCampaigns: "creative-hub-campaigns?populate=deep",
    getInsightsReports:
      "advertising-reports?populate=deep&sort=publishedDate:DESC",
    getCaseStudies: "advertising-case-studies",
  },
  trustCenter: {
    getDetails: "trust-center?populate=deep",
  },
};

export const CDN_ENDPOINTS = {
  AWS: "https://web-staging.glance-cdn.com",
  GCP: "https://web.glance-cdn.com",
};
