import Link from "next/link";
import { FunctionComponent, useEffect, useState } from "react";
import { MobileMenuItemContainer } from "./styles";
import { useRouter } from "next/router";

interface MenuItemProps {
  title: string;
  menuChildren: any[];
  handleExpandedMenu: (value: any) => void;
  expand: boolean;
  handleMenuClose: () => void;
  titleLink: string;
}

export const MobileMenuItem: FunctionComponent<MenuItemProps> = (props) => {
  const [expanded, setExpanded] = useState<boolean>(props.expand);
  const router = useRouter();

  useEffect(() => {
    setExpanded(props.expand);
  }, [props.expand]);

  const handleOpen = () => {
    setExpanded(!expanded);
    props.handleExpandedMenu(props.title);
  };

  const highlightMenuItem = (children: any[]) => {
    let isActive = false;
    children.map((child: any) => {
      if (
        child.url === router.pathname ||
        (router.pathname.includes(child.url) &&
          router.pathname != "/" &&
          child.url != "/")
      ) {
        console.log("test url : ", router.pathname, child.url);

        isActive = true;
      }
    });
    return isActive;
  };

  return (
    <MobileMenuItemContainer
      isActive={
        expanded ||
        (props.menuChildren.length > 0
          ? highlightMenuItem(props.menuChildren)
          : props.titleLink === router.pathname ||
            (router.pathname.includes(props.titleLink) &&
              router.pathname != "/" &&
              props.titleLink != "/"))
      }
      rotateArrow={expanded}
    >
      <div className="heading" onClick={handleOpen}>
        {props.menuChildren.length > 0 ? (
          <div>{props.title}</div>
        ) : (
          <div>
            <Link href={props.titleLink}>{props.title}</Link>
          </div>
        )}

        {props.menuChildren.length > 0 && (
          <svg
            width="15"
            height="8"
            viewBox="0 0 15 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L7.5 7L14 1"
              stroke={expanded ? "#F93D66" : "white"}
              strokeLinecap="round"
            />
          </svg>
        )}
      </div>
      {props.menuChildren.length > 0 && (
        <div className="children-container">
          {expanded &&
            props.menuChildren.map((children: any, index: number) => {
              return (
                <Link href={children?.url} key={index}>
                  <div className="children-item">{children.fieldName}</div>
                </Link>
              );
            })}
        </div>
      )}
    </MobileMenuItemContainer>
  );
};
