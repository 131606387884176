import axios from "axios";
import { getCookie } from "../../utils/cookies";
import APIRequest from "./APIRequest";
import HTTPResponse from "./HTTPResponse";
import { ClientLocaleToCmsLocaleMapping } from "../../utils/client-locale-to-cms-locale-mapping";

const regionToLocale: any = {
  in: "en",
  id: "id",
  us: "en",
};

export const fetchRequest = (request: APIRequest): Promise<HTTPResponse> => {
  let headers = {
    "Content-Type": "application/json",
    charset: "UTF-8",
  };

  if ("object" === typeof request.headers) {
    headers = { ...headers, ...request.headers };
  }

  return new Promise((resolve, reject): void => {
    const fetchOptions: any = {
      body: JSON.stringify(request.body),
      method: request.method,
      headers,
    };
    fetchOptions["mode"] = "no-cors";
    let url = `${process.env.API_HOST}/api/${request.url}`;
    if (request.region) {
      let locale = regionToLocale[request.region];
      if (locale) {
        url = `${url}&locale=${locale}`;
      }
    }

    const urlToBeChanged = new URL(url);
    const params = new URLSearchParams(urlToBeChanged.search);
    const locale = params.get("locale") || "en";

    ClientLocaleToCmsLocaleMapping[locale] &&
      params.set("locale", ClientLocaleToCmsLocaleMapping[locale]);

    urlToBeChanged.search = params.toString();

    // Convert the URL object back to a string and assign it to the url variable
    url = urlToBeChanged.toString();

    console.log("Url called: " + url);

    fetch(url, {
      method: request.method,
      headers,
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response?.data || response?.error) {
          let errorResponse = new HTTPResponse(response);
          errorResponse.statusCode = response?.error?.status;
          resolve(errorResponse);
        }
        resolve(new HTTPResponse(response));
      })
      .catch((err) => {
        // console.error(err);
        const response = new HTTPResponse({});
        response.data = null;
        reject(response);
      });
  });
};
