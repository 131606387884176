export const ClientLocaleToCmsLocaleMapping: any = {
  en: "en",
  id: "id",
  "id-ID": "id-ID",
  jp: "ja",
  "ja-JP": "ja-JP",
  "en-IN": "en-IN",
  us: "en-US",
  row: "rw",
};
