import Link from "next/link";
import { useRouter } from "next/router";
import { FunctionComponent, useEffect, useState } from "react";
import {
  cookiePolicyTranslations,
  deleteAllCookies,
  getCookie,
  setCookie,
} from "../../../utils/cookies";
import {
  CookieConsentAcceptButton,
  CookieConsentButtonWrapper,
  CookieConsentText,
  CookieConsentWrapper,
  CookieDeclineButton,
} from "./styles";
import axios from "axios";

import gsap from "gsap";

import { useTranslation } from "next-i18next";
interface CookieProps {
  consentText: string;
  userRegion: string;
}

const Cookie: FunctionComponent<CookieProps> = (props) => {
  const { t } = useTranslation("common");
  const [open, setOpen] = useState<boolean>(false);
  const router = useRouter();
  const [innerHeight, setInnerHeight] = useState(0);
  const [cookieConsentScroll, setCookieConsentScroll] = useState(false);
  const [isCountryIndia, setIsCountryIndia] = useState(true);

  useEffect(() => {
    if (open && cookieConsentScroll && props.userRegion != "in") {
      gsap.to(".cookie-consent-wrapper", {
        opacity: 0,
        y: "100",
        duration: 0,
        onComplete: () => {
          gsap.to(".cookie-consent-wrapper", {
            opacity: 1,
            y: "0",
            zIndex: 99,
            duration: 1,
          });
        },
      });
    } else {
      gsap.to(".cookie-consent-wrapper", {
        opacity: 0,
        y: "100",
        duration: 0,
      });
    }
  }, [open, cookieConsentScroll]);

  const setHeight = () => {
    if (!cookieConsentScroll) {
      if (
        window.scrollY >
        (document.documentElement.scrollHeight -
          document.documentElement.clientHeight) /
          5
      ) {
        setCookieConsentScroll(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", setHeight);

    return () => {
      window.removeEventListener("scroll", setHeight);
    };
  }, []);

  useEffect(() => {
    try {
      if (getCookie("cookie-pref")) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    } catch (err) {
      setOpen(true);
    }
  }, [router.pathname]);

  const acceptAllCookies = () => {
    closeCookieBanner("accepted");
    setOpen(false);
  };

  const declineAllCookies = () => {
    deleteAllCookies();
    // let firstGaScript = document.getElementById("analytics-script");
    // let secondGaScript = document.getElementById("ga-script-data");
    // firstGaScript?.parentElement?.removeChild(firstGaScript);
    // secondGaScript?.parentElement?.removeChild(secondGaScript);
    closeCookieBanner("decline");
    setOpen(false);
  };

  // const acceptCustomCookies = () => {
  // };

  const closeCookieBanner = (consent: string) => {
    setCookie("cookie-pref", consent, 365);
    // if (consent === "decline") {
    //   window.location.reload();
    // }
    setOpen(false);
  };
  return (
    <>
      <CookieConsentWrapper className="cookie-consent-wrapper">
        <CookieConsentText>
          {props.consentText}{" "}
          <Link href="/cookie-policy" target={"_blank"}>
            {
              cookiePolicyTranslations[(router as any).locale || "en"]
                ?.read_more
            }
          </Link>
        </CookieConsentText>
        <CookieConsentButtonWrapper>
          <CookieDeclineButton onClick={declineAllCookies}>
            {cookiePolicyTranslations[(router as any).locale || "en"]?.decline}
          </CookieDeclineButton>
          {/* <CookieCustomButton onClick={acceptCustomCookies}>
        Custom
      </CookieCustomButton> */}
          <CookieConsentAcceptButton onClick={acceptAllCookies}>
            {cookiePolicyTranslations[(router as any).locale || "en"]?.accept}
          </CookieConsentAcceptButton>
        </CookieConsentButtonWrapper>
      </CookieConsentWrapper>
    </>
  );
};

export default Cookie;
