/* eslint-disable @next/next/no-img-element */
import Link from "next/link";
import { FunctionComponent, useEffect, useState } from "react";
import { CDN_ENDPOINTS } from "../../../constants/endpoints/endpoints";
import { event } from "../../../utils/gtag";
import { FooterLinkIcon } from "../../icons/footer-link-arrow";
import { useRouter } from "next/router";
import {
  CompanyLogo,
  CopyrightSection,
  CopyrightText,
  DesktopFooter,
  FooterContainer,
  FooterItem,
  FooterItemsBody,
  FooterItemsHeader,
  FooterLeftBody,
  FooterLinksWrapper,
  FooterRightBody,
  MobileBottomBody,
  MobileFooterContainer,
  MobileFooterContainerMain,
  SocialMediaIcon,
  SocialMediaSection,
} from "./styles";

interface FooterProps {
  socialMediaItems: SocialMediaItem[];
  copyrightImageUrl: string;
  copyrightText: string;
  footerGroups: any[];
}

export interface IFooterLink {
  id: string;
  fieldName: string;
  url: string;
  visible: boolean;
}

export interface IFooterLink {
  id: string;
  fieldName: string;
  url: string;
  visible: boolean;
}

export interface SocialMediaItem {
  id: string;
  altText: string;
  link: string;
  icon: any;
}

export const Footer: FunctionComponent<FooterProps> = (props) => {
  const router = useRouter();
  const [expandedSection, setExpandedSection] = useState<string>();
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  // const minSwipeDistance = 5;

  // const onTouchStart = (e: any) => {
  //   setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
  //   setTouchStart(e.targetTouches[0].clientY);
  // };

  // const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientY);

  // const onTouchEnd = () => {
  //   if (!touchStart || !touchEnd) return;
  //   const distance = touchStart - touchEnd;
  //   const moveDown = distance < -minSwipeDistance;
  //   if (moveDown) {
  //     let targetPosition = Math.floor(window.pageYOffset - 500);

  //     if (router.pathname === "/") {
  //       window.scrollTo({
  //         top: targetPosition,
  //         left: 0,
  //         behavior: "smooth",
  //       });
  //     }
  //   }
  //   // add your conditional logic here
  // };

  //   useEffect(() => {}, []);

  useEffect(() => {
    setExpandedSection("");
  }, [router.pathname]);

  const handleLinkClick = (e: any) => {
    event({
      action: "click",
      category: "footer links click",
      label: `footer link ${e.target.getAttribute("data-url")} click`,
      value: e.target.getAttribute("data-url"),
    });
  };

  const generateFooterLinks = (data: IFooterLink[]) => {
    return data.map((item: IFooterLink, index: number) => {
      return (
        <FooterItem key={index}>
          <Link data-url={item.url} onClick={handleLinkClick} href={item.url}>
            {item.fieldName}
          </Link>
        </FooterItem>
      );
    });
  };

  const generateFooterGroups = () => {
    return props.footerGroups?.map((data: any) => {
      return (
        <FooterLinksWrapper key={data.id}>
          <FooterItemsHeader
            data-link-section={data.title}
            onClick={handleFooterLinkClick}
          >
            {data.title}
            {data.items?.length > 0 && (
              <div>
                <FooterLinkIcon isOpen={expandedSection === data.title} />
              </div>
            )}{" "}
          </FooterItemsHeader>
          <FooterItemsBody isOpen={expandedSection === data.title}>
            {generateFooterLinks(data.items)}
          </FooterItemsBody>
        </FooterLinksWrapper>
      );
    });
  };

  const generateSocialMediaIcons = () => {
    return props.socialMediaItems.map(
      (item: SocialMediaItem, index: number) => {
        return (
          <Link href={item.link || ""} key={index} target="_blank">
            <SocialMediaIcon
              alt="scm"
              loading="lazy"
              src={`${item.icon?.data[0]?.attributes?.url}`}
            ></SocialMediaIcon>
          </Link>
        );
      }
    );
  };

  const handleFooterLinkClick = (e: any) => {
    if (
      e?.currentTarget?.getAttribute("data-link-section") === expandedSection
    ) {
      setExpandedSection("");
    } else {
      setExpandedSection(e?.currentTarget?.getAttribute("data-link-section"));
    }
  };

  return (
    <>
      <FooterContainer>
        <FooterRightBody>
          <CompanyLogo>
            <img
              src={`${CDN_ENDPOINTS.GCP}/images/desk_uploads/header_logo.png`}
              alt={""}
            />
          </CompanyLogo>
          <div className="desktop-section">
            <SocialMediaSection>
              {generateSocialMediaIcons()}
            </SocialMediaSection>
            <CopyrightSection
              dangerouslySetInnerHTML={{ __html: props.copyrightImageUrl }}
            ></CopyrightSection>
          </div>
        </FooterRightBody>
        <FooterLeftBody>{generateFooterGroups()}</FooterLeftBody>
        <MobileBottomBody>
          <SocialMediaSection>{generateSocialMediaIcons()}</SocialMediaSection>
          <CopyrightSection
            dangerouslySetInnerHTML={{ __html: props.copyrightImageUrl }}
          ></CopyrightSection>
        </MobileBottomBody>
      </FooterContainer>

      <MobileFooterContainerMain>
        <MobileFooterContainer>
          <FooterLeftBody>{generateFooterGroups()}</FooterLeftBody>
          <MobileBottomBody>
            <SocialMediaSection>
              {generateSocialMediaIcons()}
            </SocialMediaSection>
            <CopyrightSection
              dangerouslySetInnerHTML={{ __html: props.copyrightImageUrl }}
            ></CopyrightSection>
          </MobileBottomBody>
        </MobileFooterContainer>
        <CopyrightText>© All rights reserved</CopyrightText>
      </MobileFooterContainerMain>
    </>
  );
};
