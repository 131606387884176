import { read } from "fs";

export function setCookie(name: string, value: any, days: number) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/;";
}
export function getCookie(name: string) {
  try {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
  } catch (err) {}

  return null;
}
export function eraseCookie(name: string) {
  document.cookie =
    name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;";
}

export function deleteAllCookies() {
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    if (name !== "userRegion") {
      document.cookie =
        name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;";
    }
  }
}

export const cookiePolicyTranslations: any = {
  en: {
    cookie_policy_copy:
      "We use cookies to provide you with the best possible experience on our website. By continuing to use our site, you agree to our use of cookies.",
    decline: "Decline",
    accept: "Accept",
    read_more: "Read More",
  },
  id: {
    cookie_policy_copy:
      "Dengan mengeklik “Terima”, Anda menyetujui penggunaan cookie oleh situs web kami untuk memberikan pengalaman paling relevan dengan mengingat preferensi Anda dan kunjungan berulang. Kami menyertakan cookie untuk melacak preferensi Anda",
    decline: "Menolak",
    accept: "Menerima",
    read_more: "Read More",
  },
  "id-ID": {
    cookie_policy_copy:
      "We use cookies to provide you with the best possible experience on our website. By continuing to use our site, you agree to our use of cookies.",
    decline: "Decline",
    accept: "Accept",
    read_more: "Read More",
  },
  jp: {
    cookie_policy_copy:
      "「同意する」をクリックすると、お客様の設定を記憶し、繰り返しアクセスすることで最も関連性の高いエクスペリエンスを提供するために、当社の Web サイトが Cookie を使用することに同意したことになります。あなたの好みを追跡するためにクッキーを入れます",
    decline: "衰退",
    accept: "受け入れる",
    read_more: "続きを読む",
  },
  "ja-JP": {
    cookie_policy_copy:
      "We use cookies to provide you with the best possible experience on our website. By continuing to use our site, you agree to our use of cookies.",
    decline: "Decline",
    accept: "Accept",
    read_more: "Read More",
  },
  us: {
    cookie_policy_copy:
      "We use cookies to provide you with the best possible experience on our website. By continuing to use our site, you agree to our use of cookies.",
    decline: "Decline",
    accept: "Accept",
    read_more: "Read More",
  },
  row: {
    cookie_policy_copy:
      "We use cookies to provide you with the best possible experience on our website. By continuing to use our site, you agree to our use of cookies.",
    decline: "Decline",
    accept: "Accept",
    read_more: "Read More",
  },
};
