import styled from "styled-components";

interface ContainerProps {
  isActive?: boolean;
  rotateArrow?: boolean;
}

export const MobileMenuItemContainer = styled.div<ContainerProps>`
  .heading {
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: ${(props) => (props.isActive ? "#F93D66" : "white")};
    padding: 12px 0px;
    font-size: 14px;
  }
  .children-item {
    padding: 12px;
    font-size: 14px;
    color: white;

    &:hover {
      color: #f93d66;
    }
  }
  svg {
    transform: ${(props) =>
      props.rotateArrow ? "rotate(180deg)" : "rotate(0deg)"};
  }
`;
