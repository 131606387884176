import { FunctionComponent } from "react";

interface HamburgerIconProps {
  isOpen: boolean;
}

export const HamburgerIcon: FunctionComponent<HamburgerIconProps> = (props) => {
  return props.isOpen ? (
    <svg
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.58696 0L4.45652 3.74414H4.54348L7.41304 0H9L5.5 4.5L9 9H7.41304L4.54348 5.32617H4.45652L1.58696 9H0L3.58696 4.5L0 0H1.58696Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5 7H19" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <path d="M5 12H15" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <path d="M5 17H11" stroke="white" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};
