import styled from "styled-components";

export const CookieConsentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  background: black;
  position: fixed;
  bottom: 0;
  box-shadow: 0 -1px 3px #eaf0f6;
  padding: 12px 0px 32px 0px;
  opacity: 0;
  z-index: -1;
`;

export const CookieConsentText = styled.div`
  width: 70vw;
  color: white;
  font-size: 14px;
  margin: 12px;
  a {
    color: red;
  }
`;

export const CookieConsentButtonWrapper = styled.div`
  width: 70vw;
  display: flex;
  justify-content: flex-end;
  margin: 12px;
`;

export const CookieConsentAcceptButton = styled.button`
  margin-right: 8px !important;
  background: #fc034d !important;
  border: 1px solid #fc034d !important;
  color: white !important;
  border-radius: 12px !important;
  padding: 8px 12px !important;
  cursor: pointer;
`;

export const CookieDeclineButton = styled.button`
  margin-right: 8px !important;
  background: black !important;
  border: 1px solid #fc034d !important;
  color: #fc034d !important;
  border-radius: 12px !important;
  padding: 8px 12px !important;
  cursor: pointer;
`;

export const CookieCustomButton = styled.button`
  margin-right: 8px !important;
  background: black !important;
  border: 1px solid #fc034d !important;
  color: #fc034d !important;
  border-radius: 12px !important;
  padding: 8px 12px !important;
  cursor: pointer;
`;
