import HTTPMethod from "./HTTPMethod";
class APIRequest {
  path: string;
  region: any;
  headers: { [key: string]: string } = {};
  method: HTTPMethod = HTTPMethod.get;
  body: Record<string, unknown> | undefined;

  constructor(
    path: string,
    method: HTTPMethod,
    region?: string,
    headers?: { [key: string]: string }
  ) {
    this.path = path;
    this.method = method;
    this.headers = headers || {};
    this.region = region || null;
  }

  get url(): string {
    return `${this.path}`;
  }
}

export default APIRequest;
